import {getLeisureGameKindConfig, getLotusToken, getOnBetLeisureGames} from "@/network/leisureRequest";
import leisureConst from "./leisureConst";
import sportsConst from "./sportsConst";
import inplayConst from "./inplayConst";
import {
    RECEIVE_HIDE_LOADING, RECEIVE_SHOW_LOADING,
    RECEIVE_SPORTS_CART_ITEM,
    RECEIVE_SPORTS_CART_ITEM_DEL, RECEIVE_SPORTS_CART_ITEM_DEL_ALL
} from "@/store/mutation-types";
import {getSiteStatus, getTgame365Hash, logout, setPosition} from "@/network/userRequest";
import {getLeagues, getSportsGameCountGroupByType, getSportsGames, getSportsMoreGames} from "@/network/sportsRequest";
import {
    addMemberCasinoCash,
    getAccountBalance,
    getGameLancher,
    getGameList,
    subjectMemberCasinoCash
} from "@/network/casinoHonorRequest";

export const loginRegisterMixin = {
    computed: {
        testRegxUserName() {
            return /^[0-9a-zA-Z]{3,16}$/.test(this.user.username);
        },
        testRegxPasswd() {
            return /^.{4,29}$/.test(this.user.passwd);
        },
        testRegxNickname() {
            return /^[가-힣]{2,6}$/.test(this.user.nickname)
        }
    }
}
export const checkLoginMinxin = {
    methods: {
        checkLogin() {
            if (!this.$store.state.isLogin) {
                this.$store.state.isShowLoginPanel = true;
                this.$router.push("/front/main")
                return false;
            }
            return true;
        }
    }
}
export const checkLoginOnCreateMinxin = {
    created() {
        if (!this.$store.state.isLogin) {
            this.$store.state.isShowLoginPanel = true;
            this.$router.push("/front/main")
            return false;
        }
    }
}

export const leisureGameMixin = {
    data() {
        return {
            showMasker: true,
            leisureConst,
            sportsConst,
            leisureGameInfo: {
                betStopLimitSecond: 0,
                leisureGame: null,
                leisureSportsGames: [],
                now: null,
                beforEndSecond: 10000
            },
            loadingSecond: 10000,
            attrConfig: [],
            //사디리
            oddevenConfig: {},
            leftrightConfig: {},
            lineConfig: {},
            crossConfig: {},
            overunderConfig: {},
            //달팽이
            winnumberConfig: {},
            //fx
            jonggasellbuyConfig: {},
            cegasellbuyConfig: {},
            lmConfig: {},
            //bet365가상축구
            spConfig: {},
            souConfig: {},
            spGame: {homeTeamName: '', awayTeamName: '', homeTeamOdds: 0, drawOdds: 0, awayTeamOdds: 0},//승무패
            souGame: {homeTeamName: '', awayTeamName: '', homeTeamOdds: 0, drawOdds: 0, awayTeamOdds: 0},//오버언더
            //bet365가상개경주
            dograceConfig: {},
            horseraceConfig: {},
            dograceGame: {bedang1: 0, bedang2: 0, bedang3: 0, bedang4: 0, bedang5: 0, bedang6: 0},
            horseraceGame: {
                bedang1: 0,
                bedang2: 0,
                bedang3: 0,
                bedang4: 0,
                bedang5: 0,
                bedang6: 0,
                bedang7: 0,
                bedang8: 0,
                bedang9: 0,
                bedang10: 0,
                bedang11: 0,
                bedang12: 0,
                bedang13: 0,
                bedang14: 0,
                bedang15: 0
            },

            horseraceGameList: [],

            //섯다
            normalConfig: {},
            //나인볼
            colorConfig: {},
            muniConfig: {},

            //파워볼
            poddevenConfig: {},
            poverunderConfig: {},
            psectionConfig: {},
            pnumberConfig: {},
            poddevenoverunderConfig: {},
            powerballCrossConfig: {},
            //일반볼
            noddevenConfig: {},
            noverunderConfig: {},
            nsectionConfig: {},
            nbmsConfig: {}, //일반볼 소,중,대
            noddevenoverunderConfig: {},
            noddevenbmsConfig: {},
            normalballCrossConfig: {},

            selectedInfo: {
                selectedAttr: 0,
                selectedGameId: 0,
                selectedWay: 0,
                seletedOdd: 0,
                selectText: '',
                config: {maxBetCash: 0, minBetCash: 0, maxWinCash: 0}
            },
            lotusmode: 'main'
        }
    },
    methods: {
        selected(attr, gameId, way, odd, config, selectText) {
            if (gameId == this.selectedInfo.selectedGameId
                && attr == this.selectedInfo.selectedAttr
                && way == this.selectedInfo.selectedWay) {
                this.selectedInfo.selectedAttr = 0
                this.selectedInfo.selectedGameId = 0
                this.selectedInfo.selectedWay = 0
                this.selectedInfo.seletedOdd = 0
                this.selectedInfo.selectText = ''
                this.selectedInfo.config = {maxBetCash: 0, minBetCash: 0, maxWinCash: 0}
            } else {
                this.selectedInfo.selectedAttr = attr
                this.selectedInfo.selectedGameId = gameId
                this.selectedInfo.selectedWay = way
                this.selectedInfo.seletedOdd = odd
                this.selectedInfo.selectText = selectText
                this.selectedInfo.config = config
            }

        },
        refreshTimer() {
            this.initLeisureGame()
        },
        timerStop() {
            this.showMasker = true
            //sky경기는 초를 더 늘궈줌
            if (this.kind === leisureConst.LEISURE_KIND_SKY_SPEED_BAKARA) {
                this.loadingSecond = this.loadingSecond + 10000
            }
            if (this.kind === leisureConst.LEISURE_KIND_SKY_ODDEVEN) {
                this.loadingSecond = this.loadingSecond + 5000
            }
            setTimeout(() => {
                this.initLeisureGame();
            }, this.loadingSecond)
        },
        resetLeiserGameSelected() {
            this.selectedInfo.selectedAttr = 0
            this.selectedInfo.selectedGameId = 0
            this.selectedInfo.selectedWay = 0
            this.selectedInfo.seletedOdd = 0
            this.selectedInfo.selectText = ''
        },
        initLeisureGame() {
            if (this.kind !== leisureConst.LEISURE_KIND_SKY_SPEED_BAKARA
                && this.kind !== leisureConst.LEISURE_KIND_SKY_ODDEVEN
                && this.kind !== leisureConst.LEISURE_KIND_SKY_DICE) {
                this.$store.commit(RECEIVE_SHOW_LOADING)
            }
            getOnBetLeisureGames(this.kind, this.$moment().millisecond()).then(res => {
                if (res.data.success) {
                    this.resetLeiserGameSelected();
                    this.$set(this.leisureGameInfo, 'betStopLimitSecond', res.data.data.betStopLimitSecond)
                    this.$set(this.leisureGameInfo, 'leisureGame', res.data.data.leisureGame)
                    this.$set(this.leisureGameInfo, 'leisureSportsGames', res.data.data.leisureSportsGames)
                    this.$set(this.leisureGameInfo, 'now', res.data.data.now)

                    //Bet365가상축구일 경우
                    if (this.kind === leisureConst.LEISURE_KIND_BET365_SOCCER_PREMIERSHIP
                        || this.kind === leisureConst.LEISURE_KIND_BET365_SOCCER_SUPERLEAGUE
                        || this.kind === leisureConst.LEISURE_KIND_BET365_SOCCER_EUROCUP
                        || this.kind === leisureConst.LEISURE_KIND_BET365_SOCCER_WORLDCUP
                        || this.kind === leisureConst.LEISURE_KIND_BET365_BASKETBALL_BAKER
                        || this.kind === leisureConst.LEISURE_KIND_BET365_BASKETBALL_WARTER) {
                        this.leisureGameInfo.leisureSportsGames.forEach((game) => {
                            if (game.sportsKind === sportsConst.GAME_KIND_1X2) {
                                this.spGame = game;
                            }
                            if (game.sportsKind === sportsConst.GAME_KIND_OVERUNDER) {
                                this.souGame = game;
                            }

                        })
                    }
                    if (this.kind === leisureConst.LEISURE_KIND_BET365_DOGRACE_GOLDENHILLPARK
                        || this.kind === leisureConst.LEISURE_KIND_BET365_DOGRACE_HILLSIDEPARK) {
                        this.dograceGame = this.leisureGameInfo.leisureGame
                        this.dograceGame.bedang1 = this.dograceGame.bedang1.toFixed(2)
                        this.dograceGame.bedang2 = this.dograceGame.bedang2.toFixed(2)
                        this.dograceGame.bedang3 = this.dograceGame.bedang3.toFixed(2)
                        this.dograceGame.bedang4 = this.dograceGame.bedang4.toFixed(2)
                        this.dograceGame.bedang5 = this.dograceGame.bedang5.toFixed(2)
                        this.dograceGame.bedang6 = this.dograceGame.bedang6.toFixed(2)
                    }

                    if (this.kind === leisureConst.LEISURE_KIND_BET365_HORCERACE
                        || this.kind === leisureConst.LEISURE_KIND_BET365_HORCERACE_2
                        || this.kind === leisureConst.LEISURE_KIND_BET365_HORCERACE_3
                        || this.kind === leisureConst.LEISURE_KIND_BET365_HORCERACE_4) {

                        this.horseraceGame = this.leisureGameInfo.leisureGame
                        let names = null;
                        if (this.horseraceGame.names) {
                            names = JSON.parse(this.horseraceGame.names)
                        }
                        this.horseraceGameList = []
                        if (this.horseraceGame.bedang1) {
                            this.horseraceGameList.push({
                                'key': 1,
                                'val': this.horseraceGame.bedang1.toFixed(2),
                                'name': names == null ? '' : names[0]
                            })
                        }
                        if (this.horseraceGame.bedang2) {
                            this.horseraceGameList.push({
                                'key': 2,
                                'val': this.horseraceGame.bedang2.toFixed(2),
                                'name': names == null ? '' : names[1]
                            })
                        }
                        if (this.horseraceGame.bedang3) {
                            this.horseraceGameList.push({
                                'key': 3,
                                'val': this.horseraceGame.bedang3.toFixed(2),
                                'name': names == null ? '' : names[2]
                            })
                        }
                        if (this.horseraceGame.bedang4) {
                            this.horseraceGameList.push({
                                'key': 4,
                                'val': this.horseraceGame.bedang4.toFixed(2),
                                'name': names == null ? '' : names[3]
                            })
                        }
                        if (this.horseraceGame.bedang5) {
                            this.horseraceGameList.push({
                                'key': 5,
                                'val': this.horseraceGame.bedang5.toFixed(2),
                                'name': names == null ? '' : names[4]
                            })
                        }
                        if (this.horseraceGame.bedang6) {
                            this.horseraceGameList.push({
                                'key': 6,
                                'val': this.horseraceGame.bedang6.toFixed(2),
                                'name': names == null ? '' : names[5]
                            })
                        }
                        if (this.horseraceGame.bedang7) {
                            this.horseraceGameList.push({
                                'key': 7,
                                'val': this.horseraceGame.bedang7.toFixed(2),
                                'name': names == null ? '' : names[6]
                            })
                        }
                        if (this.horseraceGame.bedang8) {
                            this.horseraceGameList.push({
                                'key': 8,
                                'val': this.horseraceGame.bedang8.toFixed(2),
                                'name': names == null ? '' : names[7]
                            })
                        }
                        if (this.horseraceGame.bedang9) {
                            this.horseraceGameList.push({
                                'key': 9,
                                'val': this.horseraceGame.bedang9.toFixed(2),
                                'name': names == null ? '' : names[8]
                            })
                        }
                        if (this.horseraceGame.bedang10) {
                            this.horseraceGameList.push({
                                'key': 10,
                                'val': this.horseraceGame.bedang10.toFixed(2),
                                'name': names == null ? '' : names[9]
                            })
                        }
                        if (this.horseraceGame.bedang11) {
                            this.horseraceGameList.push({
                                'key': 11,
                                'val': this.horseraceGame.bedang11.toFixed(2),
                                'name': names == null ? '' : names[10]
                            })
                        }
                        if (this.horseraceGame.bedang12) {
                            this.horseraceGameList.push({
                                'key': 12,
                                'val': this.horseraceGame.bedang12.toFixed(2),
                                'name': names == null ? '' : names[11]
                            })
                        }
                        if (this.horseraceGame.bedang13) {
                            this.horseraceGameList.push({
                                'key': 13,
                                'val': this.horseraceGame.bedang13.toFixed(2),
                                'name': names == null ? '' : names[12]
                            })
                        }
                        if (this.horseraceGame.bedang14) {
                            this.horseraceGameList.push({
                                'key': 14,
                                'val': this.horseraceGame.bedang14.toFixed(2),
                                'name': names == null ? '' : names[13]
                            })
                        }
                        if (this.horseraceGame.bedang15) {
                            this.horseraceGameList.push({
                                'key': 15,
                                'val': this.horseraceGame.bedang15.toFixed(2),
                                'name': names == null ? '' : names[14]
                            })
                        }
                    }
                    try {
                        this.$refs.ltimer.startTimeCount();
                    } catch (e) {

                    }

                    this.showMasker = false

                } else {
                    this.showMasker = true
                    try {
                        this.$refs.ltimer.setSequenceText(res.data.msg)
                    } catch (e) {
                        console.log(e)
                    }
                    if (this.kind === leisureConst.LEISURE_KIND_SKY_SPEED_BAKARA
                        || this.kind === leisureConst.LEISURE_KIND_SKY_ODDEVEN
                        || this.kind === leisureConst.LEISURE_KIND_SKY_DICE) {
                        //this.$refs.ltimer.setSequenceText = "경기 초기화 중입니다"
                        setTimeout(this.initLeisureGame, 1000);
                    }
                }
                this.$store.commit(RECEIVE_HIDE_LOADING)
            })
        },
        initKindConfig(kind) {
            getLeisureGameKindConfig(kind).then(res => {
                if (res.data.success) {
                    this.loadingSecond = res.data.data
                    this.loadingSecond = (this.loadingSecond + 5) * 1000
                }
            })
        },
        getLotusToken(gameCode) {

            getLotusToken(gameCode).then(res => {
                this.token = res.data.data
                let domn = ''
                if (gameCode === 'oe') {
                    domn = 'http://stream.lotusgm02.com'
                }
                if (gameCode === 'dt') {
                    domn = 'http://stream02.lotusgm02.com'
                }
                if (gameCode === 'baca') {
                    domn = 'http://stream03.lotusgm02.com'
                }
                if (this.$store.state.ismobile) {
                    this.lotusmode = 'mid'
                } else {
                    this.lotusmode = 'mid'
                }
                this.lotusmode = 'mid'
                this.iframDomain = domn + '?gType=' + this.lotusmode + '&token=' + this.token
                setTimeout(this.send2Lotus, 1000 * 3)
                this.sessionTimer = setInterval(this.send2Lotus, 1000 * 60);
            })
        },
        send2Lotus() {

            let msg = {game: this.gameCode, domain: window.origin};

            try {
                let domn = ''
                if (this.gameCode === 'oe') {
                    domn = 'http://stream.lotusgaming01.com'
                }
                if (this.gameCode === 'dt') {
                    domn = 'http://stream02.lotusgaming01.com'
                }
                if (this.gameCode === 'baca') {
                    domn = 'http://stream03.lotusgaming01.com'
                }
                document.stream_frm.postMessage(msg, domn);
            } catch (e) {

            }

        }
    },
    created() {
        this.initLeisureGame()
        this.initLeisureGameArttributeConfig()
        this.$bus.$on('leisureBetSuccess', () => {
            this.resetLeiserGameSelected()
        })
    }
}


export const sportsFromBetAPI = {
    props: {
        item: null,
    },
    data() {
        return {
            inplayConst,
            sportsConst
        }
    },
    computed: {},
    methods: {
        selectItem(game, way, text, id) {

            if (way === sportsConst.WAY_HOME && (parseInt(game.homeSu) === 1 || game.homeOdds <= 1)) {
                return false;
            }

            if (way === sportsConst.WAY_AWAY && (parseInt(game.awaySu) === 1 || game.awayOdds <= 1)) {
                return false;
            }
            if (way === sportsConst.WAY_DRAW && (parseInt(game.drawSu) === 1 || game.drawOdds <= 1)) {
                return false;
            }
            this.$bus.$emit('addInplayGame2BetCart', {game: game, way: way, text: text, selectId: id});
        },
    }
}

export const postionMixin = {
    created() {
        setPosition(this.position)
        window.scrollTo(0, 0);
    }
}


export const tgame365Mixin = {
    data() {
        return {
            hash: '',
        }
    },
    created() {
        getTgame365Hash().then(res => {
            this.hash = res.data
            console.log(this.hash)
        })
    }
}


export const sportsMatchMixin = {
    methods: {
        getSportsGames() {
            //배팅카드내역 삭제
            this.$store.commit(RECEIVE_SPORTS_CART_ITEM_DEL_ALL)
            this.$store.commit(RECEIVE_SHOW_LOADING)
            getSportsGames(this.displayPosition).then(res => {
                if (res.data.success) {
                    this.games = res.data.data
                    let pregroupcode = '000'
                    let preleagueid = '000'
                    let prestarttime='';
                    this.games.forEach(g => {
                        try {
                            this.$set(g, 'showleaguebar', true)
                            if (g.groupCode == pregroupcode) {
                                this.$set(g, 'showleaguebar', false)
                            }
                            if (g.leagueId == preleagueid && prestarttime == g.startTime) {
                                this.$set(g, 'showleaguebar', false)
                            }

                            pregroupcode = g.groupCode
                            preleagueid = g.leagueId
                            prestarttime = g.startTime;

                            this.$set(g, 'isShow', true)
                            this.$set(g, 'selectWay', sportsConst.WAY_NONE)
                            this.$set(g, 'selectOdd', 1)
                            this.$set(g, 'moreGames', [])
                            this.$set(g, 'handicapGames', [])
                            this.$set(g, 'overunderGames', [])
                            this.$set(g, 'showMoreGames', false)

                            //배당 소수점 두자리 설정 1.8 => 1.80
                            g.homeTeamOdds = g.homeTeamOdds.toFixed(2);
                            g.awayTeamOdds = g.awayTeamOdds.toFixed(2);
                            if (g.kind === sportsConst.GAME_KIND_1X2 && g.drawOdds > 0) {
                                g.drawOdds = g.drawOdds.toFixed(2);
                            }
                            this.settingGamesShow(g)
                        } catch (e) {

                        }
                    })
                }
                this.$store.commit(RECEIVE_HIDE_LOADING)
            })
        },
        //Gametype,League선택에 의한 경기 보여주기
        settingGamesShow(g) {
            //타입이 all일경우 전부 보여줌
            if (this.$store.state.selectedGameType === sportsConst.GAME_TYPE_ALL || this.$store.state.selectedGameType === sportsConst.GAME_TYPE_NOT_SELECTED) {
                g.isShow = true
            } else {
                //해당타입만 보여줌
                g.isShow = g.league.gameTypeId === this.$store.state.selectedGameType
            }
            //리그가 선택되였을경우
            if (this.$store.state.selectedLeagueList.length > 0 && this.$store.state.selectedLeagueList.findIndex((le) => {
                return g.leagueId === le.id
            }) === -1) {
                g.isShow = false
            }
            /*if (this.displayPosition === sportsConst.GAME_DISPLAYPOSITION_1x2) {
                if (g.kind !== sportsConst.GAME_KIND_1X2) {
                    g.isShow = false;
                }
            }*/

        },
    },
    created() {
    }
}

export const sportsSelectMixin = {
    methods: {
        selectItem(game, way) {
            if (game.kind === sportsConst.GAME_KIND_HANDICAP) {
                if (game.handicapVal === 0) {
                    //return false;
                }
            }
            if (game.kind === sportsConst.GAME_KIND_OVERUNDER) {
                if (game.overunderVal === 0) {
                    return false;
                }
            }
            //최대 베팅가능 폴더수 체크
            if (this.cartItems.indexOf(game) === -1) {
                if (this.cartItems.length + 1 > this.rankConfig.betFolderMaxLimit) {
                    this.$swal({
                        title: '최대 ' + this.rankConfig.betFolderMaxLimit + '폴더 배팅가능합니다',
                        type: 'error',
                        showCancelButton: false,
                        showConfirmButton: true
                    })
                    return false;
                }
            }


            //크로스룰
            let crossable = true;
            this.cartItems.forEach((item, index) => {
                if (item.isbonus === sportsConst.NOT) {
                    let res = this.checkCrossRule(item, game, way)
                    if (!res.success) {
                        this.$swal({
                            title: res.message,
                            type: 'error',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                        crossable = false;
                    }
                }

            })
            if (!crossable) {
                return false
            }

            //저장된게임판단
            if (this.cartItems.indexOf(game) !== -1) {
                if (game.selectWay === way) {
                    this.setGame(game, sportsConst.WAY_NONE)
                    this.$store.commit(RECEIVE_SPORTS_CART_ITEM_DEL, game)
                } else {
                    game.selectWay = way
                    this.setGame(game, way)
                }
            } else {
                this.cartItems.forEach((item) => {
                    if (item.id !== game.id && item.kind !== sportsConst.GAME_KIND_1X2 && item.kind === game.kind && item.groupCode === game.groupCode) {
                        this.$store.commit(RECEIVE_SPORTS_CART_ITEM_DEL, item)
                    }
                })
                this.$store.commit(RECEIVE_SPORTS_CART_ITEM, game)
                this.setGame(game, way)
            }
        },

        setGame(game, way) {//선택팀 및 선택된 배당 설정
            this.$set(game, 'selectWay', way)
            if (way === sportsConst.WAY_NONE) {
                this.$set(game, 'selectOdd', 1)
            }
            if (way === sportsConst.WAY_HOME) {
                this.$set(game, 'selectOdd', game.homeTeamOdds)
            }
            if (way === sportsConst.WAY_DRAW) {
                this.$set(game, 'selectOdd', game.drawOdds)
            }
            if (way === sportsConst.WAY_AWAY) {
                this.$set(game, 'selectOdd', game.awayTeamOdds)
            }
        },

        checkCrossRule(game, cg, compareGameSelectedVal) {
            if (game.id === cg.id && game.selectWay === compareGameSelectedVal) {
                return {'success': true, 'message': ''}
            }

            //코로스 베팅페이지에서만 적용
            /*if (this.displayPosition !== sportsConst.GAME_DISPLAYPOSITION_1x2) {
              return {'success': true, 'message': ''}
            }*/
            let compareGame = {};
            compareGame.selectWay = compareGameSelectedVal;
            compareGame.kind = cg.kind;
            compareGame.league = cg.league;
            compareGame.groupCode = cg.groupCode;
            compareGame.mingapgame = cg.mingapgame;
            let gameTypeId = game.league.gameTypeId;
            let ruletype = null;
            let ruletypeText = ''

            if (game.displayPosition === sportsConst.GAME_DISPLAYPOSITION_1x2
                || game.displayPosition === sportsConst.GAME_DISPLAYPOSITION_HANDICAPOVERUNDER) {
                ruletype = sportsConst.CROSS_RULE_TYPE_FULLTIME
            } else {
                ruletype = sportsConst.CROSS_RULE_TYPE_HANLFTIME
                ruletypeText = '스페셜,실시간 '
            }

            //경기종목이 다르면 패스
            if (game.league.gameTypeId !== compareGame.league.gameTypeId) {
                return {'success': true, 'message': ''}
            }
            //승무패 경기가 없으면 패스
            /*if (game.kind !== sportsConst.GAME_KIND_1X2 && compareGame.kind !== sportsConst.GAME_KIND_1X2) {
              return {'success': true, 'message': ''}
            }*/
            //승무패+승무패이면 패스
            if (game.kind === (sportsConst.GAME_KIND_1X2) && compareGame.kind === sportsConst.GAME_KIND_1X2) {
                return {'success': true, 'message': ''}
            }
            //동일그룹경기가 아니면 패스
            if (game.groupCode !== compareGame.groupCode) {
                return {'success': true, 'message': ''}
            }
            let protoGame = null;
            let handicapGame = null;
            let overUnderGame = null;

            if (game.kind === (sportsConst.GAME_KIND_1X2)) {
                protoGame = game;
            } else if (game.kind === (sportsConst.GAME_KIND_HANDICAP)) {
                handicapGame = game;
            } else if (game.kind === (sportsConst.GAME_KIND_OVERUNDER)) {
                overUnderGame = game;
            }
            if (compareGame.kind === (sportsConst.GAME_KIND_1X2)) {
                protoGame = compareGame;
            } else if (compareGame.kind === (sportsConst.GAME_KIND_HANDICAP)) {
                handicapGame = compareGame;
            } else if (compareGame.kind === (sportsConst.GAME_KIND_OVERUNDER)) {
                overUnderGame = compareGame;
            }

            let rule = null;
            for (let i = 0; i < this.crossRuleConfig.length; i++) {
                if (this.crossRuleConfig[i].gameTypeId === gameTypeId && this.crossRuleConfig[i].ruletype === ruletype) {
                    rule = this.crossRuleConfig[i];
                }
            }
            if (null == rule) {
                return {'success': true, 'message': ''}
            }
            if (rule.crossable === (sportsConst.DISABLE)) {
                return {'success': false, 'message': '크로스 베팅이 허용되지 않습니다'}
            }
            if (rule.sgcrossable === (sportsConst.DISABLE)) {
                return {'success': false, 'message': rule.typeName + ' 동일경기 크로스 베팅이 허용되지 않습니다'}
            }

            //승패 핸디캡
            if (null != protoGame && null != handicapGame) {
                //승무패 홈승 + 핸디캡 홈승
                if (protoGame.selectWay === (sportsConst.WAY_HOME) && handicapGame.selectWay === (sportsConst.WAY_HOME)) {
                    let ruleStatus = rule.protoHomeHandicapHome;
                    if (ruleStatus === sportsConst.DISABLE) {
                        return {'success': false, 'message': rule.typeName + ' 동일경기 승무패 홈승, 핸디캡 홈승은 조합할수 없습니다'}
                    }
                }
                //승무패 홈승 + 핸디캡 원정승
                if (protoGame.selectWay === (sportsConst.WAY_HOME) && handicapGame.selectWay === (sportsConst.WAY_AWAY)) {
                    let ruleStatus = rule.protoHomeHandicapAway;
                    if (ruleStatus === sportsConst.DISABLE) {
                        return {'success': false, 'message': rule.typeName + ' 동일경기 승무패 홈승, 핸디캡 원정승은 조합할수 없습니다'}
                    }
                }
                //승무패 무 + 핸디캡 홈승
                if (protoGame.selectWay === (sportsConst.WAY_DRAW) && handicapGame.selectWay === (sportsConst.WAY_HOME)) {
                    let ruleStatus = rule.protoDrawHandicapHome;
                    if (ruleStatus === sportsConst.DISABLE) {
                        return {'success': false, 'message': rule.typeName + ' 동일경기 승무패 무, 핸디캡 홈승은 조합할수 없습니다'}
                    }
                }
                //승무패 무 + 핸디캡 원정승
                if (protoGame.selectWay === (sportsConst.WAY_DRAW) && handicapGame.selectWay === (sportsConst.WAY_AWAY)) {
                    let ruleStatus = rule.protoDrawHandicapAway;
                    if (ruleStatus === sportsConst.DISABLE) {
                        return {'success': false, 'message': rule.typeName + ' 동일경기 승무패 무, 핸디캡 원정승은 조합할수 없습니다'}
                    }
                }
                //승무패 원정승 + 핸디캡 홈승
                if (protoGame.selectWay === (sportsConst.WAY_AWAY) && handicapGame.selectWay === (sportsConst.WAY_HOME)) {
                    let ruleStatus = rule.protoAwayHandicapHome;
                    if (ruleStatus === (sportsConst.DISABLE)) {
                        return {'success': false, 'message': rule.typeName + ' 동일경기 승무패 원정승, 핸디캡 홈승은 조합할수 없습니다'}
                    }
                }
                //승무패 원정승 + 핸디캡 원정승
                if (protoGame.selectWay === (sportsConst.WAY_AWAY) && handicapGame.selectWay === (sportsConst.WAY_AWAY)) {
                    let ruleStatus = rule.protoAwayHandicapAway;
                    if (ruleStatus === (sportsConst.DISABLE)) {
                        return {'success': false, 'message': rule.typeName + ' 동일경기 승무패 원정승, 핸디캡 원정승은 조합할수 없습니다'}
                    }
                }

                //동일경기 최소차이경기가 아닐경우 베팅실패
                // if(this.mingapbetuse && !this.checkMinGapRule(handicapGame,null)){
                //     return {'success': false, 'message': '축구 크로스 경기는 최소배당차이 핸디/오언 경기를 선택하여 베팅가능합니다'}
                // }
            }

            //승패 오버언더
            if (null != protoGame && null != overUnderGame) {
                //승무패 홈승 + 오버
                if (protoGame.selectWay === (sportsConst.WAY_HOME) && overUnderGame.selectWay === (sportsConst.WAY_HOME)) {
                    let ruleStatus = rule.protoHomeOverunderOver;
                    if (ruleStatus === sportsConst.DISABLE) {
                        return {'success': false, 'message': rule.typeName + ' 동일경기 승무패 홈승, 오버는 조합할수 없습니다'}
                    }
                }
                //승무패 홈승 + 언더
                if (protoGame.selectWay === (sportsConst.WAY_HOME) && overUnderGame.selectWay === (sportsConst.WAY_AWAY)) {
                    let ruleStatus = rule.protoHomeOverunderUnder;
                    if (ruleStatus === sportsConst.DISABLE) {
                        return {'success': false, 'message': rule.typeName + ' 동일경기 승무패 홈승, 언더는 조합할수 없습니다'}
                    }
                }
                //승무패 무 + 오버
                if (protoGame.selectWay === (sportsConst.WAY_DRAW) && overUnderGame.selectWay === (sportsConst.WAY_HOME)) {
                    let ruleStatus = rule.protoDrawOverunderOver;
                    if (ruleStatus === sportsConst.DISABLE) {
                        return {'success': false, 'message': rule.typeName + ' 동일경기 승무패 무, 오버는 조합할수 없습니다'}
                    }
                }
                //승무패 무 + 언더
                if (protoGame.selectWay === (sportsConst.WAY_DRAW) && overUnderGame.selectWay === (sportsConst.WAY_AWAY)) {
                    let ruleStatus = rule.protoDrawOverunderUnder;
                    if (ruleStatus === sportsConst.DISABLE) {
                        return {'success': false, 'message': rule.typeName + ' 동일경기 승무패 무, 언더는 조합할수 없습니다'}
                    }
                }
                //승무패 원정승 + 오버
                if (protoGame.selectWay === (sportsConst.WAY_AWAY) && overUnderGame.selectWay === (sportsConst.WAY_HOME)) {
                    let ruleStatus = rule.protoAwayOverunderOver;
                    if (ruleStatus === (sportsConst.DISABLE)) {
                        return {'success': false, 'message': rule.typeName + ' 동일경기 승무패 원정승, 오버는 조합할수 없습니다'}
                    }
                }
                //승무패 원정승 + 언더
                if (protoGame.selectWay === (sportsConst.WAY_AWAY) && overUnderGame.selectWay === (sportsConst.WAY_AWAY)) {
                    let ruleStatus = rule.protoAwayOverunderUnder;
                    if (ruleStatus === (sportsConst.DISABLE)) {
                        return {'success': false, 'message': rule.typeName + ' 동일경기 승무패 원정승, 언더는 조합할수 없습니다'}
                    }
                }
                // if(this.mingapbetuse && !this.checkMinGapRule(overUnderGame,null)){
                //     return {'success': false, 'message': '축구 크로스 경기는 최소배당차이 핸디/오언 경기를 선택하여 베팅가능합니다'}
                // }
            }

            //핸디캡 오버언더
            if (null != handicapGame && null != overUnderGame) {

                //핸디캡 홈승 + 오버
                if (handicapGame.selectWay === (sportsConst.WAY_HOME) && overUnderGame.selectWay === (sportsConst.WAY_HOME)) {
                    let ruleStatus = rule.handicapHomeOverunderOver;
                    if (ruleStatus === (sportsConst.DISABLE)) {
                        return {
                            'success': false,
                            'message': ruletypeText + rule.typeName + ' 동일경기 핸디캡 홈승, 오버는 조합할수 없습니다'
                        }
                    }
                }

                //핸디 홈승  + 언더
                if (handicapGame.selectWay === (sportsConst.WAY_HOME) && overUnderGame.selectWay === (sportsConst.WAY_AWAY)) {
                    let ruleStatus = rule.handicapHomeOverunderUnder;
                    if (ruleStatus === sportsConst.DISABLE) {
                        return {
                            'success': false,
                            'message': ruletypeText + rule.typeName + ' 동일경기 핸디캡 홈승, 언더는 조합할수 없습니다'
                        }
                    }
                }

                //핸디캡 원정승 + 오버
                if (handicapGame.selectWay === (sportsConst.WAY_AWAY) && overUnderGame.selectWay === (sportsConst.WAY_HOME)) {
                    let ruleStatus = rule.handicapAwayOverunderOver;
                    if (ruleStatus === sportsConst.DISABLE) {
                        return {
                            'success': false,
                            'message': ruletypeText + rule.typeName + ' 동일경기 핸디캡 원정승, 오버는 조합할수 없습니다'
                        }
                    }
                }
                //핸디캡 원정승 + 언더
                if (handicapGame.selectWay === (sportsConst.WAY_AWAY) && overUnderGame.selectWay === (sportsConst.WAY_AWAY)) {
                    let ruleStatus = rule.handicapAwayOverunderUnder;
                    if (ruleStatus === sportsConst.DISABLE) {
                        return {
                            'success': false,
                            'message': ruletypeText + rule.typeName + ' 동일경기 핸디캡 원정승, 언더는 조합할수 없습니다'
                        }
                    }
                }

                if (this.mingapbetuse && !this.checkMinGapRule(handicapGame, overUnderGame)) {
                    return {'success': false, 'message': '축구 크로스 경기 경기는 최소배당차이 핸디/오언 경기를 선택하여 베팅가능합니다'}
                }
            }


            return {'success': true, 'message': ''}
        },

        checkMinGapRule(game1, game2) {
            if (null != game1 && !(game1.league.gameTypeId === sportsConst.GAME_TYPE_SOCCER)) {
                return true
            }
            if (null != game2 && !(game2.league.gameTypeId === sportsConst.GAME_TYPE_SOCCER)) {
                return true
            }
            let flag = false;
            if (game1 != null && game1.mingapgame === 1) {
                flag = true;
            }
            if (game2 != null && game2.mingapgame === 1) {
                flag = true;
            }
            return flag;
        },

        moreGames(game) {
            if (game.gc <= 0) {
                return false;
            }
            if (game.handicapGames.length > 0 || game.overunderGames.length > 0) {
                game.showMoreGames = !game.showMoreGames;
            } else {
                game.showMoreGames = true
                this.$store.commit(RECEIVE_SHOW_LOADING)
                getSportsMoreGames(game.groupCode).then(res => {

                    if (res.data.success) {
                        let h = res.data.data.handicap
                        let handicap = []
                        h.forEach(rmg => {
                            try {
                                this.$set(rmg, 'isbonus', sportsConst.NOT)
                                rmg.homeTeamOdds = rmg.homeTeamOdds.toFixed(2);
                                rmg.awayTeamOdds = rmg.awayTeamOdds.toFixed(2);
                            } catch (e) {
                                console.log(e)
                            }
                            handicap.push(rmg)
                        })
                        game.handicapGames = handicap

                        let ou = res.data.data.overunder
                        let overunder = []
                        ou.forEach(rmg => {
                            try {
                                this.$set(rmg, 'isbonus', sportsConst.NOT)
                                rmg.homeTeamOdds = rmg.homeTeamOdds.toFixed(2);
                                rmg.awayTeamOdds = rmg.awayTeamOdds.toFixed(2);
                            } catch (e) {
                                console.log(e)
                            }
                            overunder.push(rmg)
                        })
                        game.overunderGames = overunder
                    }
                    this.$store.commit(RECEIVE_HIDE_LOADING)

                })
            }

        },

    },
}

export const sportsGameCountMixin = {
    methods: {
        getGameCount() {
            getSportsGameCountGroupByType(sportsConst.INPLAY_NOT, this.$store.state.displayPostion).then(res => {
                if (res.data.success) {
                    let map = res.data.data;
                    this.$store.state.gameCount.soccer = map[sportsConst.GAME_TYPE_SOCCER] !== undefined ? map[sportsConst.GAME_TYPE_SOCCER] : 0
                    this.$store.state.gameCount.baseball = map[sportsConst.GAME_TYPE_BASEBALL] !== undefined ? map[sportsConst.GAME_TYPE_BASEBALL] : 0
                    this.$store.state.gameCount.basketball = map[sportsConst.GAME_TYPE_BASKETBALL] !== undefined ? map[sportsConst.GAME_TYPE_BASKETBALL] : 0
                    this.$store.state.gameCount.volleyball = map[sportsConst.GAME_TYPE_VOLLEYBALL] !== undefined ? map[sportsConst.GAME_TYPE_VOLLEYBALL] : 0
                    this.$store.state.gameCount.hockey = map[sportsConst.GAME_TYPE_HOCKEY] !== undefined ? map[sportsConst.GAME_TYPE_HOCKEY] : 0
                    this.$store.state.gameCount.football = map[sportsConst.GAME_TYPE_USA_FOOTBALL] !== undefined ? map[sportsConst.GAME_TYPE_USA_FOOTBALL] : 0
                    this.$store.state.gameCount.esports = map[sportsConst.GAME_TYPE_ESPORTS] !== undefined ? map[sportsConst.GAME_TYPE_ESPORTS] : 0
                    this.$store.state.gameCount.alltypecount = this.$store.state.gameCount.soccer
                        + this.$store.state.gameCount.baseball + this.$store.state.gameCount.basketball
                        + this.$store.state.gameCount.volleyball + this.$store.state.gameCount.hockey
                        + this.$store.state.gameCount.football + this.$store.state.gameCount.esports;
                }
            })
        },
        initLeagues() {
            getLeagues({'displayPosition': this.$store.state.displayPostion}).then(res => {
                if (res.data.success) {
                    this.$store.state.leagueList = res.data.data;
                    //체크된 리그를 active
                    this.$store.state.leagueList.forEach(league => {
                        this.$set(league, 'checked', false)
                    })
                }
            })
        },

    },
    created() {

    },
}

export const casinoMixin = {
    data() {
        return {
            currentSlotCode: 'EVOPLAY',
            slotGameList: [],
            sportsConst,
            casinocash: 0,
            position: "Casino",
            searchVal: '',
        }
    },
    methods: {
        change(type) {
            this.type = type
        },
        search() {
            if (this.searchVal === '') {
                this.slotGameList.forEach(g => {
                    this.$set(g, 'isshow', true)
                });
            } else {

                this.slotGameList.forEach(g => {
                    if (g['langs'].ko.indexOf(this.searchVal) !== -1) {
                        this.$set(g, 'isshow', true)
                    } else {
                        this.$set(g, 'isshow', false)
                    }

                });
            }
        },
        openGame(gameId, vendor, gtype) {
            this.$store.commit(RECEIVE_SHOW_LOADING)
            getGameLancher(gameId, vendor, gtype).then(res => {
                if (res.data.success) {
                    let url = res.data.data;
                    window.open(url, '_blank')
                } else {
                    this.$swal({
                        title: res.data.msg,
                        type: 'error',
                        confirmButtonText: '확인',
                        showCancelButton: false,
                        showConfirmButton: true
                    })
                }
                this.$store.commit(RECEIVE_HIDE_LOADING)
            })
        },
        getGameListByCode(vendor) {
            this.$store.commit(RECEIVE_SHOW_LOADING)

            getGameList(vendor).then(res => {
                if (res.data.success) {
                    this.slotGameList = JSON.parse(res.data.data);
                    this.slotGameList.forEach(g => {
                        this.$set(g, 'isshow', true)
                    });
                } else {
                    this.$swal({
                        title: res.data.msg,
                        type: 'error',
                        confirmButtonText: '확인',
                        showCancelButton: false,
                        showConfirmButton: true,
                    })
                }
                this.$store.commit(RECEIVE_HIDE_LOADING)
            });
        },
        change2casinocash() {
            if (this.casinocash > this.$store.state.userInfo.cash) {
                this.$swal({
                    title: '보유금액 ' + this.$store.state.userInfo.cash + '원을 초과하였습니다',
                    type: 'error',
                    confirmButtonText: '확인',
                    showCancelButton: false,
                    showConfirmButton: true
                })
                return false;
            }
            addMemberCasinoCash(this.casinocash).then(res => {
                if (res.data.success) {
                    this.$swal({
                        title: '전환성공',
                        type: 'success',
                        confirmButtonText: '확인',
                        showCancelButton: false,
                        showConfirmButton: true
                    })
                    this.casinocash = 0;
                    this.$store.dispatch('actionUserInfo')
                    setTimeout(() => {
                        this.getCasinoBalance();
                    }, 1300)

                } else {
                    this.$swal({
                        title: '전환실패, ' + res.data.msg,
                        type: 'error',
                        confirmButtonText: '확인',
                        showCancelButton: false,
                        showConfirmButton: true
                    })
                }
            })
        },
        change2sportsmoney() {
            this.$swal({
                title: '보유머니로 전환 하시겠습니까?',
                type: 'question',
                confirmButtonText: ' 예 ',
                cancelButtonText: '아니오'
            }).then((res) => {
                this.betInfo = {selectedVals: []}
                if (res.value) {
                    subjectMemberCasinoCash().then((res) => {
                        if (res.data.success) {
                            this.$swal({
                                title: '전환성공',
                                type: 'success',
                                confirmButtonText: '확인',
                                showCancelButton: false,
                                showConfirmButton: true
                            })
                            this.casinocash = 0;
                            this.$store.dispatch('actionUserInfo')
                            setTimeout(() => {
                                this.getCasinoBalance();
                            }, 1300)
                        } else {
                            this.$swal({
                                title: '전환실패, ' + res.data.msg,
                                type: 'error',
                                confirmButtonText: '확인',
                                showCancelButton: false,
                                showConfirmButton: true
                            })
                        }

                    })
                }
            })

        },
        getCasinoBalance() {
            getAccountBalance().then(res => {
                this.$set(this.$store.state.userInfo, 'casinocash', res.data.data)
            })
        },
    },
    created() {

    }
}

export const maintaingMixin = {
    created() {
        getSiteStatus().then(res => {
            if (!res.data.success) {
                this.$store.state.isMaintaining = true;
                this.$store.state.maintainingMessage = res.data.msg
                this.$store.state.isLogin = false;
                this.$store.state.isShowLoginPanel = false;
                this.$router.replace("/memberlogin");
                logout();
            } else {
                this.$store.state.isMaintaining = false;
                this.$store.state.maintainingMessage = ''
            }
        })
    }
}




