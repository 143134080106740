import {
    RECEIVE_IS_SHOW_TOPBAR,
    RECEIVE_SHOW_LOADING,
    RECEIVE_HIDE_LOADING,
    RECEIVE_SPORTS_CART_ITEM,
    RECEIVE_SPORTS_CART_ITEM_DEL,
    RECEIVE_SPORTS_CART_ITEM_DEL_ALL,
    RECEIVE_USER_INFO,//회원정보
    RECEIVE_MINIGAMES,//미니게임
    RECEIVE_SPORTS_LIVE,//스포츠라이브
    RECEIVE_SPORTS_ORIGINAL,//스포츠경기
    RECEIVE_UNCHEKCED_MESSAGE_COUNT,
    RECEIVE_UNCHEKCED_CUSTOMERCENTER_COUNT,
    RECEIVE_COMMON_CONFIG,//스포츠경기,
    RECEIVE_CHECKED_GAME_TYPE,
    RECEIVE_CHECKED_LEAUGE,
    RECEIVE_CHECKED_LEAUGE_DEL,
    RECEIVE_CHECKED_LEAUGE_DELALL,
    RECEIVE_DISPLAY_POSTION,
    RECEIVE_INPLAY_CART_ITEM,
    RECEIVE_INPLAY_CART_ITEM_DEL_ALL,
    RECEIVE_INPLAY_CART_ITEM_DEL,
    RECEIVE_SHOW_LOADING_INPLAY, RECEIVE_HIDE_LOADING_INPLAY,
    RECEIVE_CURRENTLEISUREGAMECOMP,
    RECEIVE_CURRENTLEISUREGAMEID, INIT_USERINFO,

} from './mutation-types'
import sportsConst from "../common/sportsConst";

export default {
    [RECEIVE_DISPLAY_POSTION](state, postion) {
        state.displayPostion = postion;
    },
    [RECEIVE_SHOW_LOADING](state) {
        state.isLoading = true;
    },
    [RECEIVE_HIDE_LOADING](state) {
        state.isLoading = false;
    },
    [RECEIVE_SHOW_LOADING_INPLAY](state) {
        state.iild = true;
    },
    [RECEIVE_HIDE_LOADING_INPLAY](state) {
        state.iild = false;
    },
    [RECEIVE_USER_INFO](state, {userInfo}) {
        state.userInfo = userInfo;
    },
    [RECEIVE_MINIGAMES](state, {minigames}) {
        state.minigames = minigames;
    },
    [RECEIVE_SPORTS_LIVE](state, {sportsLive}) {
        state.sportsLive = sportsLive;
    },
    [RECEIVE_SPORTS_ORIGINAL](state, {sportsOriginal}) {
        state.sportsOriginal = sportsOriginal;
    },
    [RECEIVE_UNCHEKCED_MESSAGE_COUNT](state, {count}) {
        state.uncheckedMessageCount = count;
    },
    [RECEIVE_UNCHEKCED_CUSTOMERCENTER_COUNT](state, {count}) {
        state.uncheckedCustomercenterCount = count;
    },
    [RECEIVE_IS_SHOW_TOPBAR](state, {flag}) {
        state.isShowTopbar = flag;
    },
    [RECEIVE_COMMON_CONFIG](state, {commonConfig}) {
        state.commonConfig = commonConfig;
    },

    [RECEIVE_SPORTS_CART_ITEM](state, game) {
        state.sportsCartItems.push(game);
    },
    [RECEIVE_SPORTS_CART_ITEM_DEL_ALL](state) {
        state.sportsCartItems.forEach((item, index) => {
            item.selectWay = sportsConst.WAY_NONE
            item.selectOdd = 1
        })
        state.sportsCartItems = [];
    },
    [RECEIVE_SPORTS_CART_ITEM_DEL](state, game) {
        let index = state.sportsCartItems.findIndex((item) => {
            return item.id === game.id
        })
        if (index !== -1) {
            state.sportsCartItems[index].selectWay = sportsConst.WAY_NONE
            state.sportsCartItems[index].selectOdd = 1
            state.sportsCartItems.splice(index, 1);
        }
    },

    [RECEIVE_INPLAY_CART_ITEM](state, game) {
        state.inplayCartItems.push(game);
    },
    [RECEIVE_INPLAY_CART_ITEM_DEL_ALL](state) {
        state.inplayCartItems = [];
    },
    [RECEIVE_INPLAY_CART_ITEM_DEL](state, index) {
        state.inplayCartItems.splice(index, 1);
    },


    [RECEIVE_CHECKED_GAME_TYPE](state, gameType) {
        state.selectedGameType = gameType
    },
    [RECEIVE_CHECKED_LEAUGE](state, league) {
        state.selectedLeagueList.push(league)
    },
    [RECEIVE_CHECKED_LEAUGE_DEL](state, index) {
        state.selectedLeagueList.splice(index, 1);
    },
    [RECEIVE_CHECKED_LEAUGE_DELALL](state) {
        state.selectedLeagueList = [];
    },
    [RECEIVE_CURRENTLEISUREGAMECOMP](state, comp) {
        state.currentLeisureGameComp = comp;
    },
    [RECEIVE_CURRENTLEISUREGAMEID](state, id) {
        state.currentLeisureGameID = id;
    },
    [INIT_USERINFO](state){
        state.userInfo = {
            nickname: '',
            cash: 0,
            userPoint: 0,
            rank: {rankName: ''},
            myReferrer: {},
            referreCode: {},
            subordinateUsers: []
        }
        state.isLogin=false
    }

}